export enum TaskTypeEnum {
  Initial = '',
  Voice = 'voice',
  Document = 'document',
  Email = 'email',
  Event = 'event',
  Voicemail = 'voicemail',
  Callback = 'callback',
  Chat = 'chat'
}
