const region = 'eu-central-1';
const userPoolId = 'eu-central-1_NhzCeNKvk';
const userPoolWebClientId = '24bnhh5b1j0eb3125e18lmj9mr';
const amazonConnectInstanceName = 'nisabek-test-saml';
const identityPoolId = 'eu-central-1:e588041e-d129-42d4-821b-932e41b76ee6';

export const environment = {
  production: false,
  apiUrl: 'https://l34n37z0o1.execute-api.eu-central-1.amazonaws.com',
  instanceId: 'aa1bae49-808d-43e9-9ccb-3f8929986175',
  AWS: {
    connect: {
      instanceUrl: `https://${amazonConnectInstanceName}.awsapps.com/connect/ccp-v2`,
      loginUrl: `https://${amazonConnectInstanceName}.awsapps.com/connect/auth/sign-in`,
      logoutUrl: `https://${amazonConnectInstanceName}.awsapps.com/connect/logout`
    },
    lex: {
      identityPoolId,
      chatBotNameGerman: 'UIAgentBotGerman',
      chatBotNameEnglish: 'UIAgentBotEnglish',
      lexRegion: region
    },
    amplifyConfig : {
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        identityPoolId
      }
    }
  },
  translation: {
    default: 'de',
    languages: [
      { name: 'English', key: 'en' },
      { name: 'Deutsch', key: 'de' }
    ]
  }
};
